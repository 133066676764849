<template>
  <section>
    <div class="m-2 mb-0">
      <!-- Table Top -->
      <b-row class="mt-n5">

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          lg="6"
          sm="6"
          offset-sm="6"
          offset-md="6"
          offset-lg="6"
        >
          <div class="d-flex align-items-center justify-content-end div-margin">
            <b-form-input
              v-model="searchDevices"
              class="d-inline-block mr-1"
              placeholder="Search..."
              @keyup.enter="pageChanged(1)"
            />
            <b-button
              variant="primary"
              @click="pageChanged(1)"
            >
              Search
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <hr class="mb-0">
    <b-skeleton-table
      v-if="spinner"
      :rows="paginationObject.limit"
      :columns="3"
      :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
    />
    <div
      v-if="allDevices.length === 0"
      class="text-center mt-5 mb-3 font-medium-3 text-black-50"
    >No Devices Found</div>
    <div
      v-else
      class="heading-elements"
    >
      <b-table
        id="devices-table"
        :per-page="paginationObject.limit"
        :items="allDevices"
        responsive
        :fields="tableColumns"
        show-empty
        class="float: right"
      >

        <!-- Column: name -->
        <template #cell(Devices)="data">
          <b-link
            :to="
              {
                name:'single-device-user',
                params:{
                  id: data.item.id.toString(),
                  deviceName: data.item.name
                }
              }"
          >
            <span class="d-block text-nowrap devices">
              {{ data.item.name }}
            </span>
          </b-link>
        </template>
        <!-- Column: Groups -->
        <template #cell(Group)="data">
          <span
            v-for="device_has_group in data.item.device_has_group"
            :key=" device_has_group.id"
          >
            <div
              v-if="spinners[data.item.id + '' + device_has_group.device_groups.id]"
              class="spinner"
            >
              <b-spinner
                variant="primary"
                small
              />
            </div>
            <b-badge
              v-else
              href="#"
              class="group-badge"
              variant="light"
            >
              {{ device_has_group.device_groups.name }}
              <feather-icon
                icon="XIcon"
                class="device-delete-icon"
                @click="deleteDeviceFromGroup(data.item.id, device_has_group.device_groups.id)"
              />
            </b-badge>
          </span>
        </template>
        <!-- Column: Status -->
        <template
          #cell(INTERNAL-AD-MODE)="data"
          class="BannerMode con-switch"
        >
          <span class="float-right">
            <div
              v-if="childSpinner[data.item.mac_address]"
              style="width: 75px;"
              class="spinner text-center"
            >
              <b-spinner
                variant="primary"
                small
              />
            </div>
            <vs-switch
              v-else
              :value="data.item.is_banner_ad_mode_enabled"
              class="switchs"
              :style="`background-color: ${data.item.is_banner_ad_mode_enabled ? '#00E265' : '#FF0000'};color:white;`"
              @input="updateSwitchState( data.item.mac_address, !data.item.is_banner_ad_mode_enabled)"
            >
              <span
                slot="on"
                style="font-weight: bold"
              >Disable</span>
              <span
                slot="off"
                style="font-weight: bold"
              >Enable</span>
            </vs-switch>
          </span>
        </template>
      </b-table>
    </div>
    <div class="mx-2 mb-2">
      <CustomPagination
        v-if="allDevices.length"
        :total="paginationObject.total"
        :page="paginationObject.offset"
        :per-page="paginationObject.limit"
        @perPageChanged="perPageChanged"
        @pageChanged="pageChanged"
      />
    </div>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BLink,
  BSpinner,
  BBadge,
  BButton,
  BSkeletonTable,
  BFormInput, VBToggle,

} from 'bootstrap-vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import CustomPagination from '@/components/common/CustomPagination.vue'
import { showErrorMessage, showToast } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    CustomPagination,
    BRow,
    BCol,
    BTable,
    BLink,
    BSpinner,
    BBadge,
    BSkeletonTable,
    BButton,
    BFormInput,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      spinner: false,
      searchDevices: '',
      allDevices: [],
      tableColumns: [
        { key: 'Devices' },
        { key: 'Group' },
        {
          key: 'INTERNAL-AD-MODE',
          thClass: 'pull-right text-right textTransform hide',
        },
      ],
      spinners: {},
      childSpinner: {},
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  async mounted() {
    await this.getAllDevices(this.paginationObject)
  },
  methods: {

    getDevices() {
      this.getAllDevices(this.paginationObject)
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllDevices(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllDevices(this.paginationObject)
    },
    async getAllDevices({ offset, limit }) {
      this.spinner = true
      try {
        const { data: { data } } = await this.$axios.get(`user/device?offset=${offset - 1}&limit=${limit}&search_query=${this.searchDevices}`)
        this.allDevices = data.results
        // console.log(this.allDevices)
        this.paginationObject.total = data.total
        this.spinner = false
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('All Devices', 'You are not authorized for this action!', 'danger')
          this.spinner = false
        }
      } finally {
        this.spinner = false
      }
    },
    deleteDeviceFromGroup(deviceId, deviceGroupId) {
      const data = {
        deviceId,
        deviceGroupId,
      }
      Vue.set(this.spinners, `${deviceId}${deviceGroupId}`, true)
      return this.$axios
        .delete('/device-group', { data })
        .then(async () => {
          await this.getAllDevices(this.paginationObject)
          Vue.set(this.spinners, `${deviceId}${deviceGroupId}`, false)
          this.$emit('updateGroups')
          showToast('Devices Group', 'Device from group has been deleted successfully!')
        }).catch(({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) => {
          if (showErrorMessage(statusCode)) {
            showToast('Devices Group', message.toString(), 'danger')
            Vue.set(this.spinners, `${deviceId}${deviceGroupId}`, false)
          }
        })
        .finally(() => Vue.set(this.spinners, `${deviceId}${deviceGroupId}`, false))
    },
    updateSwitchState(macAddress, status) {
      Vue.set(this.childSpinner, macAddress, true)
      const data = {
        is_banner_ad_mode_enabled: status,
      }
      this.$axios
        .put(`user/device/update-ad/${macAddress}`, data)
        .then(async () => {
          await this.getAllDevices(this.paginationObject)
          Vue.set(this.childSpinner, macAddress, false)
          showToast('Devices Group', `Internal Ad Mode ${status ? 'enabled' : 'disabled'} successfully!`)
        }).catch(({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) => {
          if (showErrorMessage(statusCode)) {
            showToast('Devices Group', message.toString(), 'danger')
            Vue.set(this.childSpinner, macAddress, false)
          }
        }).finally(() => Vue.set(this.childSpinner, macAddress, false))
    },
  },
}
</script>
<style lang="scss">
.all-devices{
  .group-badge{
    color: #000000;
    cursor: default;
    font-size: 12px;
    margin: 5px;
  }
  .device-delete-icon{
    font-weight: 400;
    color: #6e6b7b;
    margin-left: 3px;
    cursor: pointer;
    transition: all 0.5s;
    &:hover{
      color: blue;
    }
  }
  .card:last-of-type{
    margin-bottom: 10px;
  }
}

.vs__actions {
  font-weight: 100 !important;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.switchs {
  width: 50px;
  padding-inline: 40px;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  // background-color: #7FF0B2;
  color: white;
}
.devices{
  font-size: 14px;
  line-height: 11px;
  color: #1f58b5;
  font-weight: 500;
  font-family: "Montserrat";
}
.skeleton-loading-table{
  th, td{
    padding: 1rem 2rem !important;
    height: 3.1rem !important;
    .b-skeleton-text{
      height: 1.2rem;
      margin-bottom: 0;
    }
  }
}
@media (max-width: 575px) {
  .div-margin{
    margin-top: 50px;
  }

}
</style>
